import { observer } from 'mobx-react';
import classNames from 'classnames';

import { useStore } from '@/store';
import AvatarIllustration from '@/components/avatar-illustration';

export const UserAvatar: React.FC<{
  extrasmall?: boolean;
  small?: boolean;
  large?: boolean;
  extralarge?: boolean;
  border?: boolean;
  white?: boolean;
  user?: { avatar?: string; photo?: { thumbnail: string } };
}> = observer(({ extrasmall, small, large, extralarge, border, white, user }) => {
  const store = useStore();

  const userModel = typeof user !== 'undefined' ? user : store.user;

  const className = classNames([
    'c-avatar',
    {
      'c-avatar--extra-small': extrasmall,
      'c-avatar--small': small,
      'c-avatar--large': large,
      'c-avatar--extra-large': extralarge,
      'c-avatar--border': border,
      'c-avatar--white': white,
    },
  ]);

  if (userModel?.photo) {
    return <img src={userModel.photo.thumbnail} className={classNames(className, 'u-pt-0')} />;
  }

  return <AvatarIllustration avatarId={userModel?.avatar} className={className} />;
});

export default UserAvatar;
