import classNames from 'classnames';

export const Offcanvas: React.FC<{ isActive?: boolean }> = ({ children, isActive }) => {
  return (
    <div className={classNames('c-offcanvas', 'u-hidden@md', { 'is-active': isActive })}>
      <div className="c-offcanvas__content">
        <div className="o-container">
          <div className="o-row">
            <div className="o-col-12">
              <nav className="c-offcanvas__menu">{children}</nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offcanvas;
