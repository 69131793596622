import { useState } from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import { observer } from 'mobx-react';

import { useStore } from '@/store';

import LogoutLink from '@/components/header/logout-link';
import MenuLinkItem from '@/components/header/menu-link-item';
import MenuDropdown from '@/components/header/menu-dropdown';
import ProfileMenuDropdown from '@/components/header/profile-menu-dropdown';
import Offcanvas from '@/components/header/offcanvas';
import MenuItemCount from '@/components/header/menu-item-count';

import TrueNorthLogo from '@/assets/icons/true-north-logo.svg';

const MainNavigationItems = observer(({ inOffcanvas }: { inOffcanvas?: boolean } = {}) => {
  const store = useStore();
  return (
    <>
      <MenuLinkItem href="/experiences">
        <a>Experiences</a>
        <MenuDropdown inOffcanvas={inOffcanvas}>
          <MenuLinkItem href="/experiences">
            <a>My Experiences</a>
          </MenuLinkItem>
          <MenuLinkItem href="/experiences/new">
            <a>Add Experience</a>
          </MenuLinkItem>
        </MenuDropdown>
      </MenuLinkItem>
      <MenuLinkItem href="/guides">
        <a>
          Guides <MenuItemCount>{store.user?.pending_guides_count}</MenuItemCount>
        </a>
      </MenuLinkItem>
      <MenuLinkItem href="/students">
        <a>
          Students <MenuItemCount>{store.user?.pending_students_count}</MenuItemCount>
        </a>
      </MenuLinkItem>
      <MenuLinkItem href="/support">
        <a>Support</a>
      </MenuLinkItem>
      {store.user?.is_admin && (
        <MenuLinkItem href="/admin">
          <a>Admin</a>
        </MenuLinkItem>
      )}
    </>
  );
});

export const Header: React.FC<{ clean?: boolean }> = ({ clean }) => {
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);

  return (
    <div>
      <header className={classNames('c-header', { 'c-header--transparent': clean })}>
        <div className="o-container">
          <div className="o-row">
            <div className="o-col-12">
              {clean ? (
                <div className="c-header__inner">
                  <TrueNorthLogo className="c-header__logo c-logo o-svg-icon o-svg-fill" />
                </div>
              ) : (
                <div className="c-header__inner">
                  <Link href="/">
                    <a>
                      <TrueNorthLogo className="c-header__logo c-logo o-svg-icon o-svg-fill" />
                    </a>
                  </Link>
                  <nav className="c-header__menu">
                    <ul>
                      <MainNavigationItems />
                      <li>
                        <ProfileMenuDropdown />
                      </li>
                    </ul>
                  </nav>
                  <button
                    className="c-hamburger-icon c-hamburger-icon--slider js-hamburger-icon js-offcanvas-toggle u-bg-primary-grey-0 u-ml-auto u-hidden@md"
                    type="button"
                    onClick={(event) => {
                      event.preventDefault();
                      setIsOffcanvasOpen((isOpen) => !isOpen);
                    }}
                  >
                    <span className="c-hamburger-icon__box">
                      <span className="c-hamburger-icon__inner"></span>
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
      <Offcanvas isActive={isOffcanvasOpen}>
        <ul>
          <MainNavigationItems inOffcanvas />
          <MenuLinkItem href="/my-profile">
            <a>My Profile</a>
          </MenuLinkItem>
          <li>
            <LogoutLink />
          </li>
        </ul>
      </Offcanvas>
    </div>
  );
};

export default Header;
