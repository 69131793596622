import getConfig from 'next/config';

import DefaultAvatar from '@/assets/icons/avatar-default.svg';

const unisexAvatarIds = [
  'SM_R1_4',
  'SM_R2_4',
  'SM_R3_4',
  'SM_R4_4',
  'SM_R1_8',
  'SM_R2_8',
  'SM_R3_8',
  'SM_R4_8',
];

const { publicRuntimeConfig } = getConfig();
const AVATARS = publicRuntimeConfig.AVATARS as [string, string][];
export const avatarsList = new Map(AVATARS);
export const femaleAvatarsList = new Map(
  AVATARS.filter(([avatarId]) => {
    if (unisexAvatarIds.indexOf(avatarId) > -1) {
      return true;
    }

    return avatarId.startsWith('SF');
  })
);

export const maleAvatarsList = new Map(
  AVATARS.filter(([avatarId]) => {
    if (unisexAvatarIds.indexOf(avatarId) > -1) {
      return true;
    }

    return avatarId.startsWith('SM');
  })
);

export { DefaultAvatar };
