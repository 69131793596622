import Link from 'next/link';
import { useState, useRef } from 'react';

import useClickAway from '@/hooks/use-click-away';

import { UserAvatar } from '@/components/user/avatar';
import LogoutLink from './logout-link';
import MenuDropdown from './menu-dropdown';

export const ProfileMenuDropdown = () => {
  const trigger = useRef<HTMLDivElement>(null);
  const [profileDropdownOpen, setProfileDropdownOpen] = useState<boolean>(false);
  useClickAway(trigger, () => setProfileDropdownOpen(false));

  return (
    <div ref={trigger}>
      <a
        href="#"
        onClick={(event) => {
          event.preventDefault();
          setProfileDropdownOpen((profileDropdownOpen) => !profileDropdownOpen);
        }}
      >
        <UserAvatar small />
      </a>
      {profileDropdownOpen && (
        <MenuDropdown>
          <li>
            <Link href="/my-profile">
              <a>My Profile</a>
            </Link>
          </li>
          <li>
            <LogoutLink />
          </li>
        </MenuDropdown>
      )}
    </div>
  );
};

export default ProfileMenuDropdown;
