export interface MenuItemCount {
  children?: number;
}

export const MenuItemCount = ({ children }: MenuItemCount) => {
  if (!children) {
    return null;
  }

  return (
    <>
      {' '}
      <span className="c-header__count">{children}</span>
    </>
  );
};

export default MenuItemCount;
