import { observer } from 'mobx-react';

import { useStore } from '@/store';

export const LogoutLink = observer(() => {
  const store = useStore();

  return (
    <a
      href="#"
      onClick={(event) => {
        event.preventDefault();
        store.logout();
      }}
    >
      Logout
    </a>
  );
});

export default LogoutLink;
