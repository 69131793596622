import { SVGAttributes, useRef, useState, useEffect } from 'react';
import { useMount } from 'react-use';
import { AnimatePresence, motion } from 'framer-motion';

import { avatarsList, DefaultAvatar } from '@/utils/avatar';

interface AsyncAvatarProps extends SVGAttributes<SVGElement> {
  iconPath: string;
}

function AsyncAvatar({ iconPath, ...props }: AsyncAvatarProps): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const ref = useRef<SVGStatelessComponent>();
  const Icon = ref.current;

  useEffect(() => {
    setIsLoading(true);
    let isSubscribed = true;
    const promise = import(
      /* webpackInclude: /\.svg$/ */
      '!@svgr/webpack!@/assets/illustrations/' + iconPath + '.svg'
    ) as Promise<{ default: SVGStatelessComponent }>;

    promise
      .then((result) => {
        if (isSubscribed) {
          ref.current = result.default;
          setIsLoading(false);
        }
      })
      .catch(() => {
        if (isSubscribed) {
          setIsLoading(false);
        }
      });

    return () => {
      isSubscribed = false;
    };
  }, [iconPath]);

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        key={isLoading ? 'loading' : typeof Icon === 'undefined' ? 'default' : 'avatar'}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.4 }}
        className="u-inline-block"
      >
        {isLoading ? (
          <div className={props.className}></div>
        ) : typeof Icon === 'undefined' ? (
          <DefaultAvatar {...props} />
        ) : (
          <Icon {...props} />
        )}
      </motion.div>
    </AnimatePresence>
  );
}

export const AvatarIllustration: React.FC<SVGAttributes<SVGElement> & { avatarId?: string }> = ({
  avatarId,
  ...props
}) => {
  const avatarPath = avatarId && avatarsList.has(avatarId) ? avatarsList.get(avatarId) : undefined;

  if (avatarPath) {
    return <AsyncAvatar {...props} iconPath={avatarPath.replace(/\.svg$/, '')} />;
  }

  return <DefaultAvatar {...props} />;
};

export default AvatarIllustration;
