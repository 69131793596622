import Link, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import classNames from 'classnames';

export interface MenuLinkItemProps extends Omit<LinkProps, 'passHref'> {
  children: ReactNode | [ReactNode, ReactNode];
}

export const MenuLinkItem = ({ children, ...linkProps }: MenuLinkItemProps): JSX.Element => {
  let anchor: ReactNode;
  let submenu: ReactNode;

  if (Array.isArray(children)) {
    [anchor, submenu] = children;
  } else {
    anchor = children;
  }

  const router = useRouter();

  const isActive = router.pathname === linkProps.href.toString();
  const isChildActive = router.pathname.startsWith(linkProps.href.toString());

  return (
    <li
      className={classNames({
        'is-active': isActive,
        'is-child-active': isChildActive,
        'has-submenu': Boolean(submenu),
      })}
    >
      <Link {...linkProps}>{anchor}</Link>
      {submenu}
    </li>
  );
};

export default MenuLinkItem;
